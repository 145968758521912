// @import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
@import './theme.less'; // variables to override above

html {
  box-sizing: border-box;
  --antd-wave-shadow-color: @biomerieux-blue;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body{
  font-family: @font-family-bmx;
  margin: 0;
  // Overrides for the antd modal styles added inline
  // Todo: FIgure out why AntD is late to adding modal style to body with ant-scrolling-effect
  overflow-y: scroll !important;
  width: 100% !important;
}

html,body{
  width: 100%;
  height: 100%;
}

@page{
  font-family: Arial;
  size: Letter portrait;
  margin: 0;
}

@primary-color: #00427f;@font-family: Arial, sans-serif;