.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loading-indicator .overlay {
  position: absolute;
  background-color: #000000;
  opacity: 0.2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loading-indicator .spinner {
  width: 200px;
  height: 200px;
}
